/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1, h2, .h2, h4, .h4, h6, .h6 {margin:0.5em 0;} 
h3, .h3 {}
.responsive .h3 {margin-bottom:0; }
ul.responsive3 .slideshow_caption p.h1, .heading h1, #hero h1 { font-size: 3em; }

.topMenu .nav > li > a {font-size: 120%;}

		
/*********************
SPRITE
*********************/
		

    #logo {
        width: 154px; height: 90px;
	    background-size: 154px 90px;
	}

/*********************
LAYOUT & GRID STYLES
*********************/

	
/*********************
HEADER STYLES
*********************/
.header {
    .button, button {padding: 0.3em 1.5em;}
        .row {
            min-height: 105px;
        }
}



/**************************
HERO - Home
***************************/

body:not(.Home) #hero{
   
    overflow:hidden;
       p img {
            /*min-width: 100%;
            max-width: initial;
            height: auto;*/
        }
}


/*********************
NAVIGATION STYLES
*********************/

.topMenu {		
	.nav {		
		> li {
			
		}
	}
}



/*********************
CONTENT STYLES
*********************/

#content #leftcol > div > .first {padding:0 2em;}


/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {
	
	> div, li {
		
	}
    /*Top row needs border adding seperatley to prevent double borders*/
    > div:nth-child(4) , li:nth-child(4) {
		/*border-top: 1px solid darken($white, 25%);*/
	}
}


 #bottomBlocks {
     .block {margin-bottom:0em;}

 }

 /* LEFT BLOCKS - Mission statement*/
    #homeMission{
        .row {
            height: 60vh;
            display: flex;
            align-items: center;
        }
    }

 .cText {
   a {
      max-height:400px;
   }
}


 /*Full width slider */
ul.responsive3 {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				/*height:auto;*/
				img {
					/*max-width: 100%;
					width: 100%;
					height: auto;
					max-height: none;*/
				}
			
			}
		}
	}
}


/*********************
STICKY CTA
**********************/	

#CTA {
    width: 40%;

    &.expanded {
        width: 40%;
    }
}